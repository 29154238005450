import { createMuiTheme } from '@material-ui/core/styles';
import { Padding } from '@material-ui/core/styles/createMuiTheme';

import IstokWebTtf from '../fonts/IstokWeb/IstokWeb-Regular.ttf';

const MuiInputBaseInputPaddings: Padding = {
    Bottom: 7,
    Left: 0,
    Right: 0,
    Top: 6,
    Padding: (): string => `${6}px ${0}px ${7}px ${0}px`
};

const IstokWeb = {
    fontFamily: 'Istok Web',
    fontStyle: 'normal',
    fontDisplay: 'swap' as 'swap',
    fontWeight: 400,
    src: `
    local('IstokWeb'),
    local('IstokWeb-Regular'),
    url(${IstokWebTtf}) format('truetype')
  `,
    unicodeRange:
        'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

// A custom theme for this app
const theme = createMuiTheme({
    typography: {
        fontFamily: 'Istok Web, Arial'
    },
    paddings: {
        MuiInputBaseInput: MuiInputBaseInputPaddings
    },
    palette: {
        text: {
            secondary: '#7B7B7B',
            primary: '#000000'
        },
        listLine: '#D5D3D3'
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [IstokWeb]
            }
        },
        MuiInputBase: {
            root: {
                backgroundColor: 'transparent'
            },
            input: {
                padding: MuiInputBaseInputPaddings.Padding()
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiDivider: {
            vertical: {
                alignSelf: 'stretch',
                height: 'auto'
            }
        }
    }
});

export default theme;
