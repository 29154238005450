import { combineReducers } from 'redux';
import { createReducer, action } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { SignInPageEnum, SignInState, SignInAction } from './types';
import { signInRequestOptAsync, signInValidateOptAsync } from './actions';

const initialState: SignInState = {
    page: SignInPageEnum.PersonalId,
    personalId: '',
    errorMessage: ''
};

const pageReducer = createReducer<DeepReadonly<SignInPageEnum>, SignInAction>(initialState.page)
    .handleAction(signInRequestOptAsync.success, (state, action) => SignInPageEnum.PinCode)
    .handleAction(signInRequestOptAsync.failure, state => SignInPageEnum.PinCode);

const personalIdReducer = createReducer<DeepReadonly<string>, SignInAction>(initialState.personalId)
    .handleAction(signInRequestOptAsync.request, (state, action) => initialState.personalId)
    .handleAction(signInRequestOptAsync.success, (state, action) => action.payload)
    .handleAction(signInRequestOptAsync.failure, (state, action) => initialState.personalId);

const errorMessageReducer = createReducer<DeepReadonly<string>, SignInAction>(initialState.errorMessage)
    .handleAction(signInValidateOptAsync.request, (state, action) => initialState.errorMessage)
    .handleAction(signInValidateOptAsync.success, (state, action) => initialState.errorMessage)
    .handleAction(signInValidateOptAsync.failure, (state, action) => action.payload.message);

export default combineReducers<SignInState, SignInAction>({
    page: pageReducer,
    personalId: personalIdReducer,
    errorMessage: errorMessageReducer
});
