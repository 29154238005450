import React from 'react';
import { makeStyles, createStyles, Theme, Tabs, Tab } from '@material-ui/core';
import { DeepReadonly } from 'utility-types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tabsRoot: {
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        },
        tabRoot: {
            minWidth: '90px'
        },
        tabIndicator: {
            height: '3px'
        },
        tabTextColorPrimary: {
            '&.Mui-disabled': {
                color: theme.palette.primary.main
            },
            '&.Mui-selected': {
                color: theme.palette.text.primary
            }
        }
    })
);

type PayrollTicketsYearsProps = DeepReadonly<{
    years: number[];
    selectedYear: number;
    onSelectedYearChange(year: number): void;
}>;

const PayrollTicketsYears: React.FunctionComponent<PayrollTicketsYearsProps> = props => {
    console.warn('PayrollTicketsYears');
    const classes = useStyles();
    const tabOffset = 1; // Prvni tab je popisek Rok

    const handleChange = (event: React.ChangeEvent<{}>, selectedTabIndex: number) => {
        event.preventDefault();
        props.onSelectedYearChange(props.years[selectedTabIndex - tabOffset]);
        console.warn('Tabs changed', selectedTabIndex);
    };

    const selectedTabInde = props.years.indexOf(props.selectedYear) + tabOffset;

    return (
        <Tabs
            value={selectedTabInde}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="tabs with years for filter payroll tickets by years"
            classes={{ root: classes.tabsRoot, indicator: classes.tabIndicator }}
        >
            <Tab
                label="Rok"
                disabled
                classes={{
                    root: classes.tabRoot,
                    textColorPrimary: classes.tabTextColorPrimary
                }}
                key="rok"
            />
            {/* // TODO: Je to dobre? */}
            {props.years.map(year => (
                <Tab
                    label={year}
                    classes={{
                        root: classes.tabRoot,
                        textColorPrimary: classes.tabTextColorPrimary
                    }}
                    key={year}
                />
            ))}
        </Tabs>
    );
};

export default PayrollTicketsYears;
