import { DeepReadonly } from 'utility-types';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';

export type SignInState = DeepReadonly<{
    page: SignInPageEnum;
    personalId: string;
    errorMessage: string;
}>;

export type SignInAction = ActionType<typeof actions>;

export enum SignInPageEnum {
    PersonalId,
    PinCode
}
