import { all, call, put, takeEvery } from 'redux-saga/effects';

import { signInRequestOptAsync, signInValidateOptAsync } from './actions';

// use them in parallel
export default function* rootSaga() {
    yield all([yield takeEvery(signInRequestOptAsync.request, signInRequestOpt), yield takeEvery(signInValidateOptAsync.request, signInValidateOpt)]);
}

function* signInRequestOpt(action: ReturnType<typeof signInRequestOptAsync.request>): Generator {
    try {
        console.warn(action.payload);

        yield put(signInRequestOptAsync.success(action.payload));
    } catch (err) {
        yield put(signInRequestOptAsync.failure(err));
    }
}

function* signInValidateOpt(action: ReturnType<typeof signInValidateOptAsync.request>): Generator {
    try {
        console.warn(action.payload);

        yield put(signInValidateOptAsync.success('654321'));
    } catch (err) {
        yield put(signInValidateOptAsync.failure(err));
    }
}
