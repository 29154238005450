export class MenuItemModel {
    to: string;
    lable: string;
    icon: string;

    constructor(to: string, label: string, icon: string) {
        this.to = to;
        this.lable = label;
        this.icon = icon;
    }
}
