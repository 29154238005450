import { ReducersMapObject } from 'redux';

import { AuthenticationState, AuthenticationAction, authenticationReducer } from '../features/authentication';
import { SignInState, SignInAction, signInReducer } from '../features/signIn';
import { PayrollTicketsState, PayrollTicketsAction, payrollTicketsReducer } from '../features/payrollTickets';

export type ApplicationState = {
    authentication: AuthenticationState;
    signIn: SignInState;
    payrollTickets: PayrollTicketsState;
};

export type ApplicationAction = {} & AuthenticationAction & SignInAction & PayrollTicketsAction;

const rootReducer = {
    authentication: authenticationReducer,
    signIn: signInReducer,
    payrollTickets: payrollTicketsReducer
} as ReducersMapObject<ApplicationState, ApplicationAction>;

export default rootReducer;
