import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Newses } from '../newses';
import { PayrollTicketsContainer } from '../payrollTickets';
import { SignOut } from '../signOut';

const AuthenticatedAppRouter: React.FunctionComponent<any> = () => {
    console.warn('AuthenticatedAppRouter');

    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/newses" />
            </Route>
            <Route path="/newses">
                <Newses />
            </Route>
            <Route path="/payrolltickets">
                <PayrollTicketsContainer />
            </Route>
            <Route path="/signout">
                <SignOut />
            </Route>
            <Route path="*">
                <Redirect to="/newses" />
            </Route>
        </Switch>
    );
};

export default AuthenticatedAppRouter;
