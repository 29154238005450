import React from 'react';
import { makeStyles, Theme, createStyles, Hidden, Drawer, useTheme } from '@material-ui/core';

import MainMenuDrawerContent from './mainMenuDrawerContent';

// TODO: dat do thematu jako property
const drawerWidth = 300;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0
            }
        },
        drawerPaper: {
            width: drawerWidth
        }
    })
);

const MainMenuDrawer: React.FunctionComponent<any> = props => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <nav className={classes.drawer} aria-label="main mwenu">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={props.mobileOpen}
                    onClose={props.handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    <MainMenuDrawerContent />
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    variant="permanent"
                    open
                >
                    <MainMenuDrawerContent />
                </Drawer>
            </Hidden>
        </nav>
    );
};

export default MainMenuDrawer;
