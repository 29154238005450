import React from 'react';
import { Divider, makeStyles, Theme, createStyles, AppBar, Toolbar, IconButton, Typography, Badge, Icon } from '@material-ui/core';

import { MainMenuDrawer } from '../menu';
import AuthenticatedAppRouter from './authenticatedAppRouter';
import { Logo } from '../../components';
import { CEZ_EP_LOGO } from '../../customization/logos';

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0
            }
        },
        appBar: {
            boxShadow: 'none',
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth
            }
        },
        toolBar: {
            backgroundColor: 'white',
            paddingRight: '0px'
        },
        toolBarImage: {
            height: 32,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth
        },
        contentContainer: {
            flexGrow: 1
        },
        content: {
            flexGrow: 1,
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(10),
                marginTop: theme.spacing(10),
                marginRight: theme.spacing(10),
                marginBottom: theme.spacing(10)
            }
        },
        grow: {
            flexGrow: 1
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                width: 'fit-content'
            }
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        userInfo: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
        },
        userInfoPersonalId: {
            fontWeight: 500
        },
        userInfoAvatar: {
            width: '5rem',
            height: '5rem'
        },
        menuItemRoot: {
            paddingTop: '0px',
            paddingBottom: '0px'
        },
        menuItemDivider: {
            width: '4px',
            backgroundColor: theme.palette.primary.main
        },
        menuItemIcon: {
            fontSize: '1.2rem',
            color: theme.palette.text.secondary
        },
        menuItemText: {
            fontSize: '1.2rem'
        },
        menuItemActive: {
            width: '4px',
            backgroundColor: theme.palette.primary.main
        },
        menuItemInActive: {
            width: '0px',
            backgroundColor: 'transparent'
        },
        root: {
            display: 'flex',
            flex: '1 0 auto',
            justifyContent: 'space-between'
        },
        leftDrawer: { flexShrink: 0 },
        main: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`
            }
        }
    })
);

export const AuthenticatedApp: React.FunctionComponent<any> = () => {
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    /*   const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show notifications" color="inherit">
          <Badge badgeContent={1} color="secondary">
            <Icon className="fal fa-bell" />
          </Badge>
        </IconButton>
      </MenuItem>
    </Menu>
  ); */

    const renderDesktopMenu = (
        <React.Fragment>
            <div className={classes.sectionDesktop}>
                <Divider orientation="vertical" />
                <IconButton aria-label="show notifications">
                    <Badge /* badgeContent={1} */ color="secondary">
                        <Icon className="fal fa-bell" />
                    </Badge>
                </IconButton>
                <Divider orientation="vertical" />
                <IconButton aria-label="signout">
                    <Icon className="fal fa-power-off" />
                </IconButton>
            </div>
            {/* <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <Icon className="fal fa-bars" />
        </IconButton>
      </div> */}
        </React.Fragment>
    );
    console.warn('AuthenticatedApp');

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div id="leftDrawer" className={classes.leftDrawer}>
                    <MainMenuDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
                </div>
                <div id="main" className={classes.main}>
                    <AppBar position="fixed" className={classes.appBar}>
                        <Toolbar className={classes.toolBar}>
                            <IconButton onClick={handleDrawerToggle} className={classes.menuButton}>
                                <Icon className="fal fa-bars" />
                            </IconButton>
                            <Logo imageSrc={CEZ_EP_LOGO} classes={{ root: classes.toolBarImage }} />
                            {renderDesktopMenu}
                        </Toolbar>
                    </AppBar>

                    <main className={classes.contentContainer}>
                        <div className={classes.toolbar} />
                        <div className={classes.content}>
                            <AuthenticatedAppRouter />
                        </div>
                    </main>

                    <footer>
                        <Typography align="center" variant="subtitle1" color="textSecondary">
                            K-system.CZ s.r.o. | ZAMĚSTNANEC.online
                        </Typography>
                    </footer>
                </div>
                {/*       {renderMobileMenu}       */}
            </div>
        </React.Fragment>
    );
};
