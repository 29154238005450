import React from 'react';
import { Typography, Paper, makeStyles, createStyles, Theme } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { DeepReadonly } from 'utility-types';

import { ApplicationState } from '../../store/rootReducer';
import PayrollTicketsYears from './payrollTicketsYears';
import PayrollTicketsTable from './payrollTicketsTable';
import { PayrollTicket } from './types';
import * as actions from './actions';

import { selectPayrolTicketsBySelectedYear } from './selectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /* flex: "1" */
        },
        listRoot: {
            marginTop: theme.spacing(2),
            borderRadius: '0px',
            boxShadow: 'none'
        },
        table: {},
        tableCellRoot: {
            borderBottomColor: theme.palette.listLine
        },
        tableCellBody: {
            color: theme.palette.text.secondary,
            padding: '0px 16px'
        },
        tableCellHead: {
            color: theme.palette.text.primary
        },
        tabsRoot: {
            paddingLeft: theme.spacing(2),
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        },
        tabRoot: {
            minWidth: '90px'
        },
        tabIndicator: {
            height: '3px'
        },
        tabTextColorPrimary: {
            '&.Mui-disabled': {
                color: theme.palette.primary.main
            },
            '&.Mui-selected': {
                color: theme.palette.text.primary
            }
        }
    })
);

type PayrollTicketsContainerStateProps = DeepReadonly<{
    years: number[];
    payrollTickets: PayrollTicket[];
    selectedYear: number;
}>;

type PayrollTicketsContainerDispatchProps = {
    selectYear: typeof actions.selectYear;
};

type PayrollTicketsContainerProps = {} & PayrollTicketsContainerStateProps & PayrollTicketsContainerDispatchProps;

const PayrollTicketsContainer: React.FunctionComponent<PayrollTicketsContainerProps> = props => {
    console.warn('PayrollTicketsContainer');
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="h4" color="primary">
                Výplatní lístky
            </Typography>
            <Paper className={classes.listRoot} aria-label="list of payroll tickets">
                <PayrollTicketsYears years={props.years} selectedYear={props.selectedYear} onSelectedYearChange={props.selectYear} />
                <PayrollTicketsTable rows={props.payrollTickets} />
            </Paper>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState): PayrollTicketsContainerStateProps => {
    return {
        years: state.payrollTickets.years,
        payrollTickets: selectPayrolTicketsBySelectedYear(state),
        selectedYear: state.payrollTickets.selectedYear
    };
};

const mapDispatchToProps = (dispatch: Dispatch): PayrollTicketsContainerDispatchProps =>
    bindActionCreators(
        {
            selectYear: actions.selectYear
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(PayrollTicketsContainer);
