import React from 'react';
import { withStyles } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

type LogoClassKey = 'root';

type LogoProps = {
    imageSrc: string;
    classes: ClassNameMap<LogoClassKey>;
};

const styles = (theme: any) => ({
    root: {
        height: 32,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});

const Logo: React.FunctionComponent<LogoProps> = props => {
    return <img src={props.imageSrc} className={props.classes.root} />;
};

export default withStyles(styles)(Logo);
