import { createAsyncAction } from 'typesafe-actions';

export const signInRequestOptAsync = createAsyncAction(
    'signin/SIGN_IN_REQUEST_OTP_REQUEST',
    'signin/SIGN_IN_REQUEST_OTP_SUCCESS',
    'signin/SIGN_IN_REQUEST_OTP_FAILURE'
)<string, string, void>();

export const signInValidateOptAsync = createAsyncAction(
    'signin/SIGN_IN_VALIDATE_OTP_REQUEST',
    'signin/SIGN_IN_VALIDATE_OTP_SUCCESS',
    'signin/SIGN_IN_VALIDATE_OTP_FAILURE'
)<string, string, Error>();
