import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { CSSProperties } from '@material-ui/core/styles/withStyles';

import * as actions from './actions';
import { SignInPageEnum } from './types';
import SignInPersonalIdView from './signInPersonalIdView';
import SignInPinCodeView from './signInPinCodeView';
import { ApplicationState } from '../../store/rootReducer';
import { Typography, makeStyles, createStyles, Theme, Container } from '@material-ui/core';

type SingInProps = {
    backgroundColor: CSSProperties['backgroundColor'];
} & SingInStateProps &
    SingInDispatchProps;

type SingInStateProps = {
    page: SignInPageEnum;
    errorMessage: string;
};

type SingInDispatchProps = {
    signInRequestOptRequest: typeof actions.signInRequestOptAsync.request;
    signInValidateOtpRequest: typeof actions.signInValidateOptAsync.request;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '0px 30px 0 30px',
            minHeight: '100%',
            backgroundColor: (props: SingInProps) => props.backgroundColor
        },
        loginText: {
            color: theme.palette.primary.main,
            padding: '30px 0px 0px 0px'
        }
    })
);

const getSignInView = (props: SingInProps) => {
    switch (props.page) {
        case SignInPageEnum.PersonalId:
            return <SignInPersonalIdView backgroundColor={props.backgroundColor} requestOpt={props.signInRequestOptRequest} />;
        case SignInPageEnum.PinCode:
            return (
                <SignInPinCodeView
                    backgroundColor={props.backgroundColor}
                    validateOpt={props.signInValidateOtpRequest}
                    errorMessage={props.errorMessage}
                />
            );
        default:
            return null;
    }
};

const SingIn: React.FunctionComponent<SingInProps> = props => {
    const componet = getSignInView(props);
    const classes = useStyles(props);

    return (
        <Container maxWidth="sm" classes={{ root: classes.root }}>
            <Typography align="left" variant="h4" classes={{ root: classes.loginText }}>
                PŘIHLÁŠENÍ
            </Typography>
            {componet}
        </Container>
    );
};

const mapStateToProps = (state: ApplicationState): SingInStateProps => ({
    page: state.signIn.page,
    errorMessage: state.signIn.errorMessage
});

const mapDispatchToProps = (dispatch: Dispatch): SingInDispatchProps =>
    bindActionCreators(
        {
            signInRequestOptRequest: actions.signInRequestOptAsync.request,
            signInValidateOtpRequest: actions.signInValidateOptAsync.request
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SingIn);
