import React, { memo } from 'react';
import { makeStyles, createStyles, Theme, Table, TableHead, TableRow, TableCell, TableBody, Divider, Box, withStyles, Icon } from '@material-ui/core';
import { TableCellProps, TableCellClassKey } from '@material-ui/core/TableCell';
import { StyledComponentProps, WithStyles } from '@material-ui/core/styles/withStyles';
import { DeepReadonly } from 'utility-types';

import { PayrollTicket } from './types';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        listRoot: {
            marginTop: theme.spacing(2),
            borderRadius: '0px',
            boxShadow: 'none'
        },
        table: {},
        tableCellRoot: {
            borderBottomColor: theme.palette.listLine
        },
        tableCellBody: {
            color: theme.palette.text.secondary,
            padding: '0px 16px'
        },
        tableCellHead: {
            color: theme.palette.text.primary
        }
    })
);

const extendedTableCellStyles = (theme: Theme) =>
    createStyles({
        root: { padding: '0px' },
        wrapper: { display: 'flex' },
        body: {
            padding: '16px'
            /* cursor: "pointer" */
        },
        bodyActive: {
            padding: '16px',
            /* cursor: "pointer", */
            color: theme.palette.text.primary
        },
        indicatorActive: {
            width: '4px',
            backgroundColor: theme.palette.primary.main
        },
        indicatorInactive: {
            width: '4px',
            backgroundColor: 'transparent'
        }
    });

type ExtendedTableCellClassKey = 'wrapper' | 'active' | TableCellClassKey;

type ExtendedTableCellProps = {
    selected?: boolean;
    children: React.ReactNode;
} & TableCellProps &
    WithStyles<typeof extendedTableCellStyles> &
    StyledComponentProps<ExtendedTableCellClassKey>;

const ExtendedTableCell: React.FunctionComponent<ExtendedTableCellProps> = props => {
    console.warn('ExtendedTableCell');
    const { selected, children, classes, ...muiTableCellProps } = props;
    const { body, bodyActive, wrapper, indicatorActive, indicatorInactive, ...muiTableCellClasses } = classes;

    return (
        <TableCell className={classes.root} {...muiTableCellProps} classes={{ ...muiTableCellClasses }}>
            <div className={classes.wrapper}>
                <Divider className={selected ? classes.indicatorActive : classes.indicatorInactive} orientation="vertical" />
                <span className={selected ? classes.bodyActive : classes.body}>{children}</span>
            </div>
        </TableCell>
    );
};

const ExtendedTableCellWithStyles = withStyles(extendedTableCellStyles)(ExtendedTableCell);

type PayrollTicketsTableProps = DeepReadonly<{
    rows: PayrollTicket[];
}>;

const PayrollTicketsTable: React.FunctionComponent<PayrollTicketsTableProps> = memo(props => {
    console.warn('PayrollTicketsTable');
    const classes = useStyles();
    const defaultSelectedValue = props.rows[0] ? props.rows[0].id : -1;
    const [selected, setSelected] = React.useState<number>(defaultSelectedValue);

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        setSelected(id);
    };

    return (
        <Table className={classes.table} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <ExtendedTableCellWithStyles>Období</ExtendedTableCellWithStyles>
                    <TableCell>Název</TableCell>
                    <TableCell>Akce</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.rows.map(row => (
                    <TableRow
                        key={row.name}
                        /*  hover */
                        onClick={event => handleClick(event, row.id)}
                    >
                        <ExtendedTableCellWithStyles
                            selected={selected === row.id ? true : false}
                            classes={{ body: classes.tableCellBody }}
                            align="left"
                            component="th"
                            scope="row"
                            variant="body"
                        >
                            {row.name}
                        </ExtendedTableCellWithStyles>
                        <TableCell classes={{ body: classes.tableCellBody }} align="left" variant="body">
                            {row.name}
                        </TableCell>
                        <TableCell classes={{ body: classes.tableCellBody }} align="left" variant="body">
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Icon className="fal fa-download" fontSize="small" color="primary" title="Stáhnout" style={{ cursor: 'pointer' }} />
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
});

export default PayrollTicketsTable;
