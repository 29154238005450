import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { PayrollTicketsState, PayrollTicketsAction } from './types';
import * as actions from './actions';

const currentYear = new Date().getFullYear();

const initialState: PayrollTicketsState = {
    years: [currentYear + 1, currentYear],
    selectedYear: currentYear,
    tayrollTicketList: {
        byId: {
            1: { id: 1, year: 2019, file: 'file1', name: 'VL-201912-ID123456', period: '12/2019' },
            2: { id: 2, year: 2019, file: 'file2', name: 'VL-201911-ID123456', period: '11/2019' },
            3: { id: 3, year: 2019, file: 'file3', name: 'VL-201910-ID123456', period: '10/2019' },
            4: { id: 4, year: 2019, file: 'file4', name: 'VL-201909-ID123456', period: '09/2019' },
            5: { id: 5, year: 2019, file: 'file5', name: 'VL-201908-ID123456', period: '08/2019' },
            6: { id: 6, year: 2019, file: 'file6', name: 'VL-201907-ID123456', period: '07/2019' },
            7: { id: 7, year: 2019, file: 'file7', name: 'VL-201906-ID123456', period: '06/2019' },
            8: { id: 8, year: 2019, file: 'file8', name: 'VL-201905-ID123456', period: '05/2019' },
            9: { id: 9, year: 2019, file: 'file9', name: 'VL-201904-ID123456', period: '04/2019' },
            10: { id: 10, year: 2019, file: 'file10', name: 'VL-201903-ID123456', period: '03/2019' },
            11: { id: 11, year: 2019, file: 'file11', name: 'VL-201902-ID123456', period: '02/2019' },
            12: { id: 12, year: 2019, file: 'file12', name: 'VL-201901-ID123456', period: '01/2019' },
            13: { id: 13, year: 2020, file: 'file13', name: 'VL-202001-ID123456', period: '01/2020' },
            14: { id: 14, year: 2020, file: 'file14', name: 'VL-202002-ID123456', period: '02/2020' },
            15: { id: 15, year: 2020, file: 'file15', name: 'VL-202003-ID123456', period: '03/2020' }
        },
        allIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
    }
};

const yearsReducer = createReducer<typeof initialState.years>(initialState.years);
const selectedYearReducer = createReducer<Readonly<typeof initialState.selectedYear>, PayrollTicketsAction>(initialState.selectedYear).handleAction(
    actions.selectYear,
    (state, action) => action.payload
);
const tayrollTicketListReducer = createReducer<Readonly<typeof initialState.tayrollTicketList>>(initialState.tayrollTicketList);
/* .handleAction(
    signInRequestOptAsync.success,
    (state, action) => SignInPageEnum.PinCode
  )
  .handleAction(signInRequestOptAsync.failure, state => SignInPageEnum.PinCode); */

/* const personalIdReducer = createReducer<DeepReadonly<string>, PayrollTicketsAction>(
  initialState.personalId
);
.handleAction(
    signInRequestOptAsync.request,
    (state, action) => initialState.personalId
  )
  .handleAction(
    signInRequestOptAsync.success,
    (state, action) => action.payload
  )
  .handleAction(
    signInRequestOptAsync.failure,
    (state, action) => initialState.personalId
  ); */

/* const errorMessageReducer = createReducer<DeepReadonly<string>, PayrollTicketsAction>(
  initialState.errorMessage
);
.handleAction(
    signInValidateOptAsync.request,
    (state, action) => initialState.errorMessage
  )
  .handleAction(
    signInValidateOptAsync.success,
    (state, action) => initialState.errorMessage
  )
  .handleAction(
    signInValidateOptAsync.failure,
    (state, action) => action.payload.message
  ); */

export default combineReducers<PayrollTicketsState>({
    years: yearsReducer,
    selectedYear: selectedYearReducer,
    tayrollTicketList: tayrollTicketListReducer
});
