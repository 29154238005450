import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Input } from '../../components';
import { nameofFactory } from '../../utils/nameofFactory';
import { signInPersonalIdValidationSchema } from './validations';
import { useSignInStyles } from './styles';

type SingInPersonalIdViewProps = {
    backgroundColor: CSSProperties['backgroundColor'];

    requestOpt: (parsonalId: string) => void;
};

type SingInPersonalId = Yup.InferType<typeof signInPersonalIdValidationSchema>;

const nameof = nameofFactory<SingInPersonalId>();

const SingInPersonalIdView: React.FunctionComponent<SingInPersonalIdViewProps> = props => {
    const classes = useSignInStyles(props);

    return (
        <Formik<SingInPersonalId>
            initialValues={{ personalId: '' }}
            onSubmit={(values: SingInPersonalId, { setSubmitting }): void => {
                setSubmitting(true);

                props.requestOpt(values.personalId);
            }}
            validationSchema={signInPersonalIdValidationSchema}
        >
            {props => {
                const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                return (
                    <form onSubmit={handleSubmit}>
                        <Typography align="left" variant="h6" classes={{ root: classes.infoText }}>
                            Pro přihlášení zadejte svoje osobní číslo a na uložené telefonní číslo Vám zašleme PIN pomocí SMS.
                        </Typography>
                        <Grid container className={classes.grid} spacing={3}>
                            <Grid item xs={12}>
                                {/* // TODO: - pridat do konstanty a projit cely kod */}
                                {/* // TODO: - udelat formik wrapper a pouzit hook useField z formik API */}
                                <Input
                                    iconClassName="fal fa-id-card-alt"
                                    labelText="Osobní číslo"
                                    helperText={touched.personalId ? errors.personalId : ''}
                                    error={Boolean(touched.personalId) && Boolean(errors.personalId)}
                                    InputProps={{
                                        name: nameof('personalId'),
                                        onChange: handleChange,
                                        onBlur: handleBlur,
                                        value: values.personalId
                                    }}
                                />
                            </Grid>
                            <Grid item container justify="flex-end" xs={12}>
                                <Button variant="contained" title="Zaslat PIN kód" color="primary" type="submit" disabled={isSubmitting}>
                                    Zaslat PIN kód
                                </Button>
                            </Grid>
                        </Grid>
                        <Typography align="left" variant="h6" color="primary" className={classes.noAccountText}>
                            Ještě nemáte účet ?
                        </Typography>
                        <Typography align="left" variant="h6" className={classes.text}>
                            Zašlete požadavek na založení účtu na email registrace@cez-produkty.cz a vyčkejte budeme vás kontaktovat.
                        </Typography>
                    </form>
                );
            }}
        </Formik>
    );
};

export default SingInPersonalIdView;
