import React from 'react';
import { connect } from 'react-redux';

import { AuthenticatedApp } from './authenticatedApp';
import { default as UnauthenticatedApp } from './unauthenticatedApp';
import { AppProviderProps } from './types';
import { ApplicationState } from '../../store/rootReducer';

// TODO: dodelat progress indicator
export const AppProvider: React.FunctionComponent<AppProviderProps> = props => {
    const { user } = props;

    /* return user ? <AuthenticatedApp /> : <UnauthenticatedApp />; */
    return <AuthenticatedApp />;
};

const mapStateToProps = (state: ApplicationState) => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(AppProvider);
