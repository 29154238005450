import { combineReducers } from 'redux';
import { createReducer, ActionType } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

import { AuthenticationState, AuthenticationAction, User } from './types';
import { loadAuthenticatedUserFormStorageAsync } from './actions';
import { signInValidateOptAsync } from '../signIn/actions';

const initialState: AuthenticationState = {
    user: null,
    isAuthenticated: false
};

type RootActions = AuthenticationAction | ActionType<typeof signInValidateOptAsync>;

const userReducer = createReducer<DeepReadonly<User | null>, RootActions>(initialState.user)
    .handleAction(loadAuthenticatedUserFormStorageAsync.request, (state, action) => null)
    .handleAction(loadAuthenticatedUserFormStorageAsync.success, (state, action) => {
        return { ...action.payload };
    })
    .handleAction(loadAuthenticatedUserFormStorageAsync.failure, state => null)
    .handleAction(signInValidateOptAsync.success, (state, action) => {
        return { firstName: action.payload, lastName: 'none', email: 'none' };
    });

const isAuthenticatedReducer = createReducer<DeepReadonly<boolean>, RootActions>(initialState.isAuthenticated)
    .handleAction(loadAuthenticatedUserFormStorageAsync.request, (state, action) => false)
    .handleAction(loadAuthenticatedUserFormStorageAsync.success, (state, action) => true)
    .handleAction(loadAuthenticatedUserFormStorageAsync.failure, state => false);

export default combineReducers<AuthenticationState, RootActions>({
    user: userReducer,
    isAuthenticated: isAuthenticatedReducer
});
