import { all, call, put, takeEvery } from 'redux-saga/effects';

import {} from './actions';

// use them in parallel
export default function* rootSaga() {
    yield all([]);
}

/* function* signInRequestOpt(
  action: ReturnType<typeof signInRequestOptAsync.request>
): Generator {
  try {
    console.warn(action.payload);
    
    yield put(signInRequestOptAsync.success(action.payload));
  } catch (err) {
    yield put(signInRequestOptAsync.failure(err));
  }
}

function* signInValidateOpt(
  action: ReturnType<typeof signInValidateOptAsync.request>
): Generator {
  try {
    console.warn(action.payload);
    
    yield put(signInValidateOptAsync.success("654321"));
  } catch (err) {
    yield put(signInValidateOptAsync.failure(err));
  }
} */
