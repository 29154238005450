import React from 'react';
import { Divider, List, ListItem, ListItemText, makeStyles, Theme, createStyles, useTheme, Icon, Box } from '@material-ui/core';
import { useRouteMatch, NavLink as ReactRouterDomNavLink } from 'react-router-dom';

import { MenuItemsConfiguration } from '../../configuration/mainMenu';
import { MenuItemModel } from './types';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        appBar: {
            boxShadow: 'none',
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth
            }
        },
        toolBar: {
            backgroundColor: 'white'
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            padding: theme.spacing(3)
        },
        grow: {
            flexGrow: 1
        },
        sectionDesktop: {
            display: 'none',
            /*  minHeight: "inherit", */
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                width: 'fit-content'
            }
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        userInfo: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
        },
        userInfoPersonalId: {
            //fontSize: "0.9rem",
            fontWeight: 500
        },
        userInfoAvatar: {
            width: '5rem',
            height: '5rem'
        },
        menuItemRoot: {
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '0px',
            '& > a': {
                textDecoration: 'none'
            },
            '&:not(:last-of-type)': {
                borderBottom: `1px solid ${theme.palette.listLine}`
            }
        },
        menuItemDivider: {
            width: '4px',
            backgroundColor: theme.palette.primary.main
        },
        menuItemIcon: {
            fontSize: '1.2rem',
            color: theme.palette.text.secondary
        },
        menuItemIconActive: {
            fontSize: '1.2rem',
            color: theme.palette.text.primary
        },
        menuItemText: {
            fontSize: '1.2rem'
        },
        // TODO: - dodelat jako jednu classu
        menuItemTextActive: {
            fontSize: '1.2rem',
            color: theme.palette.text.primary
        },
        menuItemActive: {
            width: '5px',
            backgroundColor: theme.palette.primary.main
        },
        // TODO: - dodelat jako jednu classu
        menuItem: {
            width: '5px',
            backgroundColor: theme.palette.primary.main,
            visibility: 'hidden'
        }
    })
);

const MainMenuNavLink: React.FunctionComponent<any> = props => {
    const classes = useStyles();
    const match = useRouteMatch({
        path: props.menuItem.to,
        exact: true
    });

    return (
        <ReactRouterDomNavLink to={props.menuItem.to}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Divider className={match ? classes.menuItemActive : classes.menuItem} orientation="vertical" />
                <Box display="flex" flexDirection="row" alignItems="center" p={1} width="100%">
                    <Box ml={3} mr={2} display="flex" flexDirection="row" alignItems="center">
                        <Icon classes={{ root: match ? classes.menuItemIconActive : classes.menuItemIcon }} className={props.menuItem.icon} />
                    </Box>
                    <ListItemText
                        secondary={props.menuItem.lable}
                        secondaryTypographyProps={{
                            variant: 'subtitle1',
                            classes: {
                                root: match ? classes.menuItemTextActive : classes.menuItemText
                            }
                        }}
                    />
                </Box>
            </Box>
        </ReactRouterDomNavLink>
    );
};

const MainMenuContent: React.FunctionComponent<any> = () => {
    const classes = useStyles();

    console.warn('MainMenuContent');

    return (
        <Box component="nav" pt={12}>
            <List>
                {MenuItemsConfiguration.map((menuItem: MenuItemModel) => (
                    <ListItem key={menuItem.to} className={classes.menuItemRoot}>
                        <MainMenuNavLink menuItem={menuItem} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default MainMenuContent;
