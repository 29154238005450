import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware as createRouterMiddleware, RouterRootState } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { History } from 'history';

import reducers, { ApplicationState } from './rootReducer';
import rootSaga from '../saga/rootSaga';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IExtendWindow extends Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}

export default function configureStore(history: History, initialState: ApplicationState & RouterRootState) {
    // create the saga middleware
    const sagaMiddleware = createSagaMiddleware();
    const routerMiddleware = createRouterMiddleware(history);
    const middleware = [sagaMiddleware, routerMiddleware];

    // In development, use the browser's Redux dev tools extension if installed
    const isDevelopment = process.env.NODE_ENV === 'development';

    const composeEnhancers =
        isDevelopment &&
        typeof window !== 'undefined' &&
        typeof window === 'object' &&
        ((window as unknown) as IExtendWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? ((window as unknown) as IExtendWindow).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              })
            : compose;

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware)
            // other store enhancers if any
        )
    );

    sagaMiddleware.run(rootSaga);

    return store;
}
