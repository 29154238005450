import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter, RouterRootState } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// import "typeface-roboto";
// import "typeface-istok-web"

import configureStore from './store/configureStore';
import { ApplicationState } from './store/rootReducer';
import App from './App';
import theme from './themes/theme';
import './index.scss';

// Create browser history to use in the Redux store
// const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
// const history = createBrowserHistory({ basename: baseUrl });
const history = createBrowserHistory();
const store = configureStore(history, {} as ApplicationState & RouterRootState);

const rootElement = document.getElementById('root');

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>,
    rootElement
);
