import React from 'react';
import { makeStyles, Theme, createStyles, Typography, Box } from '@material-ui/core';

import MainMenuContent from './mainMenuContent';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0
            }
        },
        appBar: {
            boxShadow: 'none',
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth
            }
        },
        toolBar: {
            backgroundColor: 'white'
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3)
        },
        grow: {
            flexGrow: 1
        },
        sectionDesktop: {
            display: 'none',
            /*  minHeight: "inherit", */
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                width: 'fit-content'
            }
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        userInfo: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            paddingTop: theme.spacing(1)
        },
        userInfoPersonalId: {
            //fontSize: "0.9rem",
            fontWeight: 500
        },
        userInfoAvatar: {
            width: '5rem',
            height: '5rem'
        },
        menuItemRoot: {
            paddingTop: '0px',
            paddingBottom: '0px'
        },
        menuItemDivider: {
            width: '4px',
            backgroundColor: theme.palette.primary.main
        },
        menuItemIcon: {
            fontSize: '1.2rem',
            color: theme.palette.text.secondary
        },
        menuItemText: {
            fontSize: '1.2rem'
        },
        menuItemActive: {
            width: '4px',
            backgroundColor: theme.palette.primary.main
        },
        menuItemInActive: {
            width: '0px',
            backgroundColor: 'transparent'
        }
    })
);

const defaultProps = {
    bgcolor: 'background.paper',
    borderColor: 'primary.main',
    m: 1,
    border: 1.5,
    style: { width: '5rem', height: '5rem' }
};

const MainMenuDrawerContent: React.FunctionComponent<any> = () => {
    const classes = useStyles();

    console.warn('MainMenuDrawerContent');

    return (
        <React.Fragment>
            <section className={classes.userInfo}>
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Box borderRadius="50%" m={2} {...defaultProps} display="flex" justifyContent="center" alignItems="center">
                        <Typography align="center" variant="h5">
                            MK
                        </Typography>
                    </Box>

                    {/* <Avatar classes={{ root: classes.userInfoAvatar }}>MK</Avatar> */}
                </Box>
                <Box mt={2}>
                    <Typography align="center" variant="h5">
                        Michal Koch
                    </Typography>
                </Box>
                <Typography className={classes.userInfoPersonalId} align="center" variant="subtitle1" color="textSecondary">
                    ID123456789
                </Typography>
            </section>
            <MainMenuContent />
        </React.Fragment>
    );
};

export default MainMenuDrawerContent;
