import { createSelector } from 'reselect';

import { ApplicationState } from '../../store/rootReducer';

const getPayrolTicketsAllIds = (state: ApplicationState) => state.payrollTickets.tayrollTicketList.allIds;
const getPayrolTicketsById = (state: ApplicationState) => state.payrollTickets.tayrollTicketList.byId;
const getPayrolTicketsSelectedYear = (state: ApplicationState) => state.payrollTickets.selectedYear;

export const selectPayrolTicketsBySelectedYear = createSelector(
    [getPayrolTicketsAllIds, getPayrolTicketsById, getPayrolTicketsSelectedYear],
    (allIds, byId, selectedYear) => {
        return allIds
            .map((id: number) => {
                return byId[id];
            })
            .filter(payrolTicket => payrolTicket.year === selectedYear);
    }
);
